<template>
    <el-form :model="form" ref="form" label-width="0"  :disabled="'0'!==deptAuth && '4' !==deptAuth">
      <el-row class="row-index">
        <el-col :span="2" class="left-col bold"> 乙肝五项</el-col>
        <el-col :span="21" class="right-col">
          <!-- 丙肝病毒RNA（HCV） -->
<!--          <el-row class="border_bottom" align="middle" type="flex">-->
<!--            <el-col :span="4" class="bold flex_center">-->
<!--              丙肝病毒RNA（HCV）-->
<!--            </el-col>-->
<!--            <el-col :span="8" class="border_left">-->
<!--              <el-form-item label="" prop="hcv">-->
<!--                <el-radio-group v-model="form.hcv">-->
<!--                  <el-radio-->
<!--                      v-for="(label) in hcv"-->
<!--                      :key="label"-->
<!--                      :label="label"-->
<!--                  ></el-radio>-->
<!--                  <el-input v-if="form.hcv === '阴性-'" v-model="form.hcv_yin" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="4" class="bold flex_center border_left">-->
<!--              梅毒螺旋体（TP）-->
<!--            </el-col>-->
<!--            <el-col :span="8" class="border_left">-->
<!--              <el-form-item label="" prop="tp">-->
<!--                <el-radio-group v-model="form.tp">-->
<!--                  <el-radio-->
<!--                      v-for="(label) in tp"-->
<!--                      :key="label"-->
<!--                      :label="label"-->
<!--                  ></el-radio>-->
<!--                  <el-input v-if="form.tp === '阴性-'" v-model="form.tp_yin" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <!-- 乙肝表面抗原（HBsAg） -->
          <el-row class="border_bottom" align="middle" type="flex">
            <el-col :span="3" class="bold flex_center">
              乙肝表面抗原（HBsAg）
            </el-col>
            <el-col :span="8" class="border_left border_left">
              <el-form-item label="" prop="hbsag">
                <el-radio-group v-model="form.hbsag">
                  <el-radio
                      v-for="(label) in hbsag"
                      :key="label"
                      :label="label"
                  ></el-radio>
                  <el-input v-if="form.hbsag === '阴性-'" v-model="form.hbsag_yin" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- 乙肝表面抗体（HBsAb） -->
            <el-col :span="3" class="bold flex_center border_left">
              乙肝表面抗体（HBsAb）
            </el-col>
            <el-col :span="8" class="border_left">
              <el-form-item label="" prop="hbsab">
                <el-radio-group v-model="form.hbsab">
                  <el-radio
                      v-for="(label) in hbsab"
                      :key="label"
                      :label="label"
                  ></el-radio>
                  <el-input v-if="form.hbsab === '阴性-'" v-model="form.hbsab_yin" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 乙肝E抗原（HBeAg） -->
          <el-row class="border_bottom" align="middle" type="flex">
            <el-col :span="3" class="bold flex_center">
              乙肝E抗原（HBeAg）
            </el-col>
            <el-col :span="8" class="border_left">
              <el-form-item label="" prop="hbeag">
                <el-radio-group v-model="form.hbeag">
                  <el-radio
                      v-for="(label) in hbeag"
                      :key="label"
                      :label="label"
                  ></el-radio>
                  <el-input v-if="form.hbeag === '阴性-'" v-model="form.hbeag_yin" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- 乙肝E抗体（HBeAb） -->
            <el-col :span="3" class="bold flex_center border_left">
              乙肝E抗体（HBeAb）
            </el-col>
            <el-col :span="8" class="border_left">
              <el-form-item label="" prop="hbeab">
                <el-radio-group v-model="form.hbeab">
                  <el-radio
                      v-for="(label) in hbeab"
                      :key="label"
                      :label="label"
                  ></el-radio>
                  <el-input v-if="form.hbeab === '阴性-'" v-model="form.hbeab_yin" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 乙肝核心抗体（HBcAb） -->
          <el-row class="border_bottom" align="middle" type="flex">
            <el-col :span="3" class="bold flex_center">
              乙肝核心抗体（HBcAb）
            </el-col>
            <el-col :span="8" class="border_left">
              <el-form-item label="" prop="hbcab">
                <el-radio-group v-model="form.hbcab">
                  <el-radio
                      v-for="(label) in hbcab"
                      :key="label"
                      :label="label"
                  ></el-radio>
                  <el-input v-if="form.hbcab === '阴性-'" v-model="form.hbcab_yin" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- 人类免疫缺陷病毒抗体（Anti-HIV） -->
<!--            <el-col :span="4" class="bold flex_center border_left">-->
<!--              人类免疫缺陷病毒抗体（Anti-HIV）-->
<!--            </el-col>-->
<!--            <el-col :span="8" class="border_left">-->
<!--              <el-form-item label="" prop="hiv">-->
<!--                <el-radio-group v-model="form.hiv">-->
<!--                  <el-radio-->
<!--                      v-for="(label) in hiv"-->
<!--                      :key="label"-->
<!--                      :label="label"-->
<!--                  ></el-radio>-->
<!--                  <el-input v-if="form.hiv === '阴性-'" v-model="form.hiv" placeholder="请输入内容" size="small" style="width: 200px; display: inline-block"></el-input>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </template>

  <script>
    import { immuneitem } from "@/utils/dict";

    export default {
      name: "",
      components: {},
      props: ['deptAuth'],
      data() {
        return {
          form: {
            hcv: '', // 免疫八项-丙肝病毒状态
            hcv_yin: '', // 免疫八项-丙肝病毒-阴性
            tp: '', // 免疫八项-梅毒螺旋体状态
            tp_yin: '', // 免疫八项-梅毒螺旋体-阴性
            hbsag: '', // 免疫八项-乙肝表面抗原状态
            hbsag_yin: '', // 免疫八项-乙肝表面抗原-阴性
            hbsab: '', // 免疫八项-乙肝表面抗体状态
            hbsab_yin: '', // 免疫八项-乙肝表面抗体-阴性
            hbeag: '', // 免疫八项-乙肝E抗原
            hbeag_yin: '', // 免疫八项-乙肝E抗原-阴性
            hbeab: '', // 免疫八项-乙肝E抗体状态
            hbeab_yin: [], // 免疫八项-肺-乙肝E抗体-阴性
            hbcab: '', // 免疫八项-乙肝核心抗体状态
            hbcab_yin: '', // 免疫八项-乙肝核心抗体-阴性
            hiv: '', // 免疫八项-人类免疫缺陷病毒抗体
            hiv_yin: '', // 免疫八项-人类免疫缺陷病毒抗体-阴性
          },
          hcv: immuneitem.hcv,
          tp: immuneitem.tp,
          hbsag: immuneitem.hbsag,
          hbsab: immuneitem.hbsab,
          hbeag: immuneitem.hbeag,
          hbeab: immuneitem.hbeab,
          hbcab: immuneitem.hbcab,
          hiv: immuneitem.hiv,
        };
      },
      computed: {

      },
      watch: {},
      mounted() {

      },
      methods: {},
    };
  </script>

  <style lang="scss">
  </style>

