<template>
  <el-form :model="form" ref="form" label-width="120px" :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">血常规</el-col>
      <el-col :span="21" class="right-col">
        <!-- 血常规 -->
        <el-row class="border_bottom" align="middle" type="flex">
          <el-col :span="24" class="">
            <el-col :span="12" class="">
              <el-form-item class="inb" label="白细胞" prop="wbc">
                <el-input v-model="form.wbc" placeholder="请输入内容" size="small">
                  <template slot="append">10*9/L</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="中性粒细胞百分比" prop="gr_per">
                <el-input v-model="form.gr_per" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="淋巴细胞百分比" prop="ly_per">
                <el-input v-model="form.ly_per" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="单核细胞百分比" prop="monor">
                <el-input v-model="form.monor" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="嗜碱性粒细胞百分比" prop="basor">
                <el-input v-model="form.basor" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="中性粒细胞浓度值（gr#）" prop="gr_abs">
                <el-input v-model="form.gr_abs" placeholder="请输入内容" size="small">
                  <template slot="append">10*9/L</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="淋巴细胞浓度值（LY#）" prop="ly_abs">
                <el-input v-model="form.ly_abs" placeholder="请输入内容" size="small">
                  <template slot="append">10*9/L</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="单核细胞浓度值（MO#）" prop="mo">
                <el-input v-model="form.mo" placeholder="请输入内容" size="small">
                  <template slot="append">10*9/L</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="嗜酸性粒细胞浓度值（EO#）" prop="eo">
                <el-input v-model="form.eo" placeholder="请输入内容" size="small">
                  <template slot="append">10*9/L</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="嗜碱性粒细胞浓度值（BAO#）" prop="baso">
                <el-input v-model="form.baso" placeholder="请输入内容" size="small">
                  <template slot="append">10*9/L</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="红细胞" prop="rbc">
                <el-input v-model="form.rbc" placeholder="请输入内容" size="small">
                  <template slot="append">10*12/L</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="血红蛋白浓度" prop="hgb">
                <el-input v-model="form.hgb" placeholder="请输入内容" size="small">
                  <template slot="append">g/l</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="平均红细胞体积" prop="mcv">
                <el-input v-model="form.mcv" placeholder="请输入内容" size="small">
                  <template slot="append">fl</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="红细胞压积" prop="hct">
                <el-input v-model="form.hct" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="平均红细胞血红蛋白含量" prop="mch">
                <el-input v-model="form.mch" placeholder="请输入内容" size="small">
                  <template slot="append">Pg</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="平均红细胞血红蛋白浓度MCHC" prop="mchc">
                <el-input v-model="form.mchc" placeholder="请输入内容" size="small">
                  <template slot="append">g/l</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="红细胞体积分布宽度RDW-CV" prop="rdw">
                <el-input v-model="form.rdw" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="红细胞体积分布宽度RDW-SD" prop="sd">
                <el-input v-model="form.sd" placeholder="请输入内容" size="small">
                  <template slot="append">fl</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="血小板" prop="plt">
                <el-input v-model="form.plt" placeholder="请输入内容" size="small">
                  <template slot="append">10*9/L</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="平均血小板体积" prop="mpv">
                <el-input v-model="form.mpv" placeholder="请输入内容" size="small">
                  <template slot="append">fl</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="血小板亚积（PCT）" prop="pct">
                <el-input v-model="form.pct" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="">
              <el-form-item class="inb" label="血小板分布宽度" prop="pdw">
                <el-input v-model="form.pdw" placeholder="请输入内容" size="small">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        baso: '', //                           嗜碱性粒细胞
        monor: '', // 		 单细胞比率
        basor: '', //             嗜碱性粒细胞比率
        sd: '', //                红细胞分布宽度SD
        hct: '', //               红细胞压积
        ly_abs: '', //              淋巴细胞绝对值（LY#）
        gr_abs: '', //               中性粒细胞绝对值（gr#）
        pct: '', //               血小板亚积（PCT）
        mo: '', //               单核细胞绝对值（MO#）
        mch: '', //               平均红细胞血红蛋白含量
        hgb: '', //               血红蛋白浓度
        mcv: '', //               红细胞平均体积
        mchc: '', //              平均红细胞血红蛋白浓度
        rdw: '', //               红细胞分布宽度
        plt: '', //               血小板
        mpv: '', //               平均血小板体积
        pdw: '', //               血小板分布宽度
        eo: '', //               嗜酸性粒细胞绝对值
        gr_per: '', //               中性粒细胞百分比
        ly_per: '', //               淋巴细胞百分比
        rbc: '', //                             红细胞
        wbc: '', //              白细胞
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>


<style scoped>

</style>
