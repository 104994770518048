<template>
  <div class="health-check-page flex" v-loading="loading">
    <transition name="slide-fade-x">
      <el-button class="pa fs16 m16" style=" z-index: 2"
                 v-show="!showTab"
                 @click="setshowTab">
        <i class="el-icon-arrow-right"></i>
      </el-button>
    </transition>
    <transition name="slide-fade-left">
      <div v-if="showTab" class="p16  pr"  style="width: 220px; padding-right: 0; z-index: 2">
        <el-button v-if="showTab" class="pa fs16" style="left: 16px; top: 16px; z-index: 2 "
          @click="setshowTab">
          <i class="el-icon-arrow-left"></i>
        </el-button>
        <el-card v-if="showTab" class="pr pl16" style="z-index: 1">
          <el-tabs v-model="activeTab" tab-position="left" @tab-click="scrollTo" style="max-height: 100vh" >
            <template v-for="item in menus" class="w100 h100">
              <el-tab-pane
                  :disabled="!item.isShow"
                  :key="item.name"
                  :name="item.name"  :label="item.label"
              ></el-tab-pane>
            </template>
          </el-tabs>
        </el-card>
      </div>
    </transition>
    <div  ref="container" class="p16 container" @scroll="onScroll" style=" width: 100%">
      <el-card ref="form" shadow="always" >
        <!-- 基础信息 -->
        <Base ref="Base" name="Base"></Base>
        <!-- 体检类型 -->
        <BodyCheckType name="BodyCheckType" ref="checkType" @childEvent="tjlxChange"></BodyCheckType>
        <!-- 一般状况 -->
        <YiBanZhuangKuang name="YiBanZhuangKuang" :deptAuth="deptAuth"></YiBanZhuangKuang>
        <!-- 口腔检查（原脏器功能） -->
        <ZangQiGongNeng name="ZangQiGongNeng" :deptAuth="deptAuth"></ZangQiGongNeng>
        <!-- 心电图 -->
        <ECG name="ECG" :deptAuth="deptAuth"></ECG>
        <!-- 彩超 -->
        <Ultrasound name="Ultrasound" :deptAuth="deptAuth" :tjlx="tjlx"></Ultrasound>
        <!-- 乙肝五项 -->
        <ImmuneItem name="ImmuneItem" :deptAuth="deptAuth"  v-show="packA || packB || packC" ></ImmuneItem>
        <!-- 血糖 -->
        <BloodGlucose name="BloodGlucose" :deptAuth="deptAuth" v-show="packA || packB || packC" ></BloodGlucose>
        <!-- 肝功能五项 -->
        <LiverFunction name="LiverFunction" :deptAuth="deptAuth" v-show="packA || packB || packC"></LiverFunction>
        <!-- 血脂四项 -->
        <Tetrapolipidemia name="Tetrapolipidemia" :deptAuth="deptAuth" v-show="packA || packB || packC"></Tetrapolipidemia>
        <!-- 肾功能三项 -->
        <RenalFunction name="RenalFunction" :deptAuth="deptAuth" v-show="packA || packB || packC"></RenalFunction>
        <!-- 血常规 -->
        <BloodRoutine name="BloodRoutine" :deptAuth="deptAuth"></BloodRoutine>
        <!-- 尿常规 -->
        <UrineRoutine name="UrineRoutine" :deptAuth="deptAuth" v-show="packB || packC"></UrineRoutine>
        <!-- 凝血四项-->
        <BloodClottingFour name="BloodClottingFour" :deptAuth="deptAuth" v-show="pack0"></BloodClottingFour>
        <!-- 术前四项-->
        <PreoperativeQuadrilateral name="PreoperativeQuadrilateral" :deptAuth="deptAuth" v-show="pack0"></PreoperativeQuadrilateral>
        <!-- 内窥镜检查-->
        <Endoscopy name="Endoscopy" :deptAuth="deptAuth" v-show="pack0"></Endoscopy>

        <!-- CT 检查-->
        <CTScan name="CTScan" :deptAuth="deptAuth" v-show="packA || packB || packC"></CTScan>
        <!-- 健康评价 -->
        <JianKangPingJia name="JianKangPingJia" :tjlx="tjlx" :get-all-form-data="()=> { return getFormData ()}"></JianKangPingJia>
        <div class="border_bottom"></div>
      </el-card>
    </div>

    <div class="bottom_toobar border_top">
      <el-button
        type=""
        size="large"
        @click="$router.back()"
        class="w200px mr16"
        ><span class="fs18 bold">返 回</span></el-button
      >
      <el-button type="primary" size="large" @click="submitForm" class="w200px">
        <span class="fs18 bold">提 交</span>
      </el-button>
    </div>
  </div>
</template>
<script>
import Base from "@/components/FormBolck/Base.vue";
import BodyCheckType from "@/components/FormBolck/BodyCheckType.vue";
// import JianChaTujing from "@/components/FormBolck/JianChaTujing.vue";
// import Zhengzhuang from "@/components/FormBolck/Zhengzhuang.vue";
import YiBanZhuangKuang from "@/components/FormBolck/YiBanZhuangKuang.vue";
// import ShengHuoFangShi from "@/components/FormBolck/ShengHuoFangShi.vue";
import ZangQiGongNeng from "@/components/FormBolck/ZangQiGongNeng.vue";
import ImmuneItem from "@/components/FormBolck/ImmuneItem.vue";
import BloodGlucose from "@/components/FormBolck/BloodGlucose.vue";
import LiverFunction from "@/components/FormBolck/LiverFunction.vue";
import ECG from "@/components/FormBolck/ECG.vue";
import PreoperativeQuadrilateral from "@/components/FormBolck/PreoperativeQuadrilateral.vue";
import BloodClottingFour from "@/components/FormBolck/BloodClottingFour.vue";
import Endoscopy from "@/components/FormBolck/Endoscopy.vue";
import CTScan from "@/components/FormBolck/CTScan.vue";
// import FuZhuJianCha from "@/components/FormBolck/FuZhuJianCha.vue";
// import XianCunZhuYaoJianKangWenTi from "@/components/FormBolck/XianCunZhuYaoJianKangWenTi.vue";
// import ZhuYuanZhiLiaoQingKuang from "@/components/FormBolck/ZhuYuanZhiLiaoQingKuang.vue";
// import ZhuYaoYongYaoQingKuang from "@/components/FormBolck/ZhuYaoYongYaoQingKuang.vue";
// import FeiMianYiGuiHuaYuFangJieZhongShi from "@/components/FormBolck/FeiMianYiGuiHuaYuFangJieZhongShi.vue";
import JianKangPingJia from "@/components/FormBolck/JianKangPingJia.vue";
// import JianKangZhiDao from "@/components/FormBolck/JianKangZhiDao.vue";
// import WeiXianYinSuKongZhi from "@/components/FormBolck/WeiXianYinSuKongZhi.vue";
// import ShengHuoFangMian from "@/components/FormBolck/ShengHuoFangMian.vue";

import { saveBodyCheckForm, getCheckDetail } from "@/http/report/detail";
import { _toCamel } from "@/utils";
import {
  Array_Prefix,
  Auth_All, Auth_CT,
  Auth_Imaging,
  Auth_Laboratory,
  Auth_Stomatology,
  Auth_Surgery,
  COMPLETED
} from "@/constant";
import lodash from "lodash";
import moment from "moment";
import Ultrasound from "@/components/FormBolck/Ultrasound.vue";
import Tetrapolipidemia from "@/components/FormBolck/Tetrapolipidemia.vue";
import RenalFunction from "@/components/FormBolck/RenalFunction.vue";
import BloodRoutine from "@/components/FormBolck/BloodRoutine.vue";
import UrineRoutine from "@/components/FormBolck/UrineRoutine.vue";

window.lodash = lodash;

export default {
  components: {
    Base,
    BodyCheckType,
    // JianChaTujing,
    // Zhengzhuang,
    YiBanZhuangKuang,
    // ShengHuoFangShi,
    ZangQiGongNeng,
    ImmuneItem,
    BloodGlucose,
    LiverFunction,
    ECG,
    Endoscopy,
    PreoperativeQuadrilateral,
    BloodClottingFour,
    Ultrasound,
    Tetrapolipidemia,
    RenalFunction,
    BloodRoutine,
    UrineRoutine,
    CTScan,
    // FuZhuJianCha,
    // XianCunZhuYaoJianKangWenTi,
    // ZhuYuanZhiLiaoQingKuang,
    // ZhuYaoYongYaoQingKuang,
    // FeiMianYiGuiHuaYuFangJieZhongShi,
    JianKangPingJia,
    // JianKangZhiDao,
    // WeiXianYinSuKongZhi,
    // ShengHuoFangMian,
  },
  data() {
    return {
      // TODO 通过选择不同套餐隐藏模块后，左侧菜单与右侧容器中的内容不一致，导致activeTab失效
      pack0: true,
      packA: true,
      packB: true,
      packC: true,

      loading: false,
      baskForm: {},
      showTab: false,
      arrayPrefix: Array_Prefix,
      activeTab: 'Base',
      menus: [],

      scrollOffset: 100,

      deptAuth: Auth_All,
      tjlx: '胃镜体检套餐'  //  体检套餐
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
    } else {
      this.$refs.Base.reset();
      this.asyncTjlx();
    }
    this.showTab = !!localStorage.showTab;
    this.deptAuth = this.$store.state.userInfo.deptAuth;
  },
  methods: {


    // 监听子组件中的变更体检套餐事件
    tjlxChange: function(checkTjlx){
      console.log('parent',checkTjlx);
      this.tjlx = checkTjlx;

      this.asyncTjlx();
    },
    // 同步套餐类型
    asyncTjlx(){
      this.pack0 = '胃镜体检套餐' === this.tjlx;
      this.packA = '体检套餐A' === this.tjlx;
      this.packB = '体检套餐B' === this.tjlx;
      this.packC = '体检套餐C' === this.tjlx;
      // this.pack0 = true;
      // this.packA = true;
      // this.packB = true;
      // this.packC = true;

      console.log("0 ",this.pack0)
      console.log("A ",this.packA)
      console.log("B ",this.packB)
      console.log("C ",this.packC)
      this.changeMenuDisabled();
    },
    // 更新目录状态
    changeMenuDisabled(){
      this.menus = [
        { name: "Base", label: "基础信息", isShow:true  },
        { name: "BodyCheckType", label: "体检类型",isShow:true  },
        { name: "YiBanZhuangKuang", label: "一般状况",isShow:true  },
        { name: "ZangQiGongNeng", label: "口腔检查", isShow:true },
        { name: "ECG" , label: "心电图" , isShow:true},
        { name: "Ultrasound" , label: "彩超", isShow:true },
        { name: "ImmuneItem", label: "乙肝五项", isShow: this.packA || this.packB || this.packC  },
        { name: "BloodGlucose", label: "血糖" , isShow: this.packA ||this.packB || this.packC },
        { name: "LiverFunction", label: "肝功能五项", isShow: this.packA || this.packB || this.packC  },
        { name: "Tetrapolipidemia" , label: "血脂四项", isShow: this.packA ||this.packB || this.packC  },
        { name: "RenalFunction" , label: "肾功能三项", isShow: this.packA ||this.packB || this.packC  },
        { name: "BloodRoutine" , label: "血常规", isShow: true  },
        { name: "UrineRoutine" , label: "尿常规", isShow: this.packB || this.packC  },
        { name: "BloodClottingFour" , label: "凝血四项", isShow: this.pack0  },
        { name: "PreoperativeQuadrilateral" , label: "术前四项", isShow: this.pack0  },
        { name: "Endoscopy" , label: "内窥镜检查", isShow: this.pack0  },
        { name: "CTScan" , label: "CT 检查", isShow: this.packA ||this.packB || this.packC   },
        { name: "JianKangPingJia", label: "健康评价",  isShow:true },
        // { name: "JianChaTujing", label: "检查途径" },
        // { name: "Zhengzhuang", label: "症状" },
        // { name: "ShengHuoFangShi", label: "生活方式" },
        // { name: "FuZhuJianCha", label: "辅助检查" },
        // { name: "XianCunZhuYaoJianKangWenTi", label: "现存主要健康问题" },
        // { name: "ZhuYuanZhiLiaoQingKuang", label: "住院治疗情况" },
        // { name: "ZhuYaoYongYaoQingKuang", label: "主要用药情况" },
        // { name: "FeiMianYiGuiHuaYuFangJieZhongShi", label: "非免疫规划预防接种史"},
        // { name: "JianKangZhiDao", label: "健康指导" },
        // { name: "WeiXianYinSuKongZhi", label: "危险控制因素" },
        // { name: "ShengHuoFangMian", label: "生活方面" },
      ];
    },
    setshowTab() {
      this.showTab = !this.showTab
      localStorage.showTab = this.showTab ? 'true' : ''
    },
    async getDetail(id) {
      this.loading = true;
      const res = await getCheckDetail({ id });
      this.loading = false;
      if (res.success) {
        this.result = res.result || {}
        this.restoreData(this.result);
        this.tjlx = this.$refs['checkType'].form.tjlx;

        console.log( 'checkType ', this.$refs['checkType'].form.tjlx);
        this.asyncTjlx();
      }
    },
    restoreData(result) {
      const forms = this.$refs.form.$children;

      // 循环每个表单
      forms.forEach((item) => {
        // 循环每个表单下的字段
        for (const _key in item.form) {
          // 下划线 转 驼峰
          const key = _toCamel(_key);
          // 取值
          const value = result[key];
          if (value !== undefined) {
            if (value && value.indexOf(this.arrayPrefix) !== -1) {
              item.form[_key] = value.replace(this.arrayPrefix, "").split(",");
            } else {
              item.form[_key] = value === null ? "" : value;
            }
          } else {
            // 不存在的字段 打印出来
            console.log(_key, key, value);
          }
        }
      });
    },
    getFormData() {
      const forms = this.$refs.form.$children;
      const data = {};
      forms.forEach((item) => {
        let form = item.getFormData ? item.getFormData() : item.form;
        for (const key in form) {
          const value = form[key];
          // 数组类型
          if (lodash.isArray(value)) {
            data[key] = this.arrayPrefix + value.filter(item =>{ return item !== '' }).join(",");
            // 日期类型
          } else if (lodash.isDate(value)) {
            const format = "YYYY-MM-DD HH:mm:ss";
            data[key] = moment(value).format(format);

            // 对象
          } else if (typeof value === "object" && value !== null) {
            data[key] = value;

            // 原始值
          } else {
            data[key] = value === null ? '' : value;
          }
        }
      });

      if (this.$route.query.id) {
        data.id = this.$route.query.id
      }
      // 体检信息各部门录入状态
      switch (this.deptAuth){
        case Auth_Surgery:
          data.surgeryStatus = COMPLETED;
          break;
        case Auth_Stomatology:
          data.stomatologyStatus = COMPLETED;
          break;
        case Auth_Imaging:
          data.imagingStatus = COMPLETED;
          break;
        case Auth_Laboratory:
          data.laboratoryStatus = COMPLETED;
          break;
        case Auth_CT:
          data.ctStatus = COMPLETED;
          break;
      }
      return data;
    },
    validate() {
      this.loading = true;
      const forms = this.$refs.form.$children;
      const list = [];
      forms.forEach((item) => {
        list.push(item.$refs.form.validate());
      });
      return Promise.all(list)
        .then((results) => {
          return results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submitForm() {
      try {
        await this.validate();
        this.loading = true;
        const res = await saveBodyCheckForm(this.getFormData());
        this.loading = false;
        if (res.success) {
          this.$message.success("提交成功");
          this.$router.replace({ name: "list" });
        }
      } catch (error) {
        this.$message.error("提交失败，请检查必填项及格式！");
        console.log(error);
      }
    },
    scrollTo() {
      const forms = this.$refs.form.$children;
      forms.forEach(item => {
        if(item.$el.getAttribute('name') === this.activeTab) {
          this.$refs.container.scrollTop = item.$el.offsetTop - this.scrollOffset
        }
      })
    },
    onScroll(e) {
      const forms = this.$refs.form.$children;
      const top = e.target.scrollTop
      forms.forEach(item => {
        if((item.$el.offsetTop - top) < (this.scrollOffset + 10)) {
          this.activeTab = item.$el.getAttribute('name')
        }
      })
    }
  },
};
</script>

<style lang="less">
.el-date-editor.el-input {
  width: 100%;
}
.health-check-page {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  .container {
    height: calc(100% - 60px);
    overflow: auto;
    scroll-behavior: smooth;
  }

  .row-index {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    border-bottom: 0;
  }

  .el-tabs__item {
    height: 32px;
    padding-left: 0;
  }

  .el-col {
    .el-form-item {
      margin: 8px 12px;
    }
  }

  .left-col {
    text-align: center;
  }
  .right-col {
    border-left: 1px solid #eee;
  }
  .el-form-item {
    margin-bottom: 0;
  }

  .el-radio,
  .el-checkbox {
    margin: 0px 12px 0px 0px;
  }

  .el-radio__label,
  .el-checkbox__label {
    padding-left: 4px;
  }

  .el-form-item__label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    min-height: 32px;
    font-weight: bold;
    vertical-align: middle;
    justify-content: flex-end;
  }

  .el-form-item__content {
    line-height: 32px;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 10px;
  }
}

.bottom_toobar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  opacity: 0.8;
  z-index: 100;
  box-shadow: 0 0 12px rgba(0,0,0, 0.2);
}
</style>
