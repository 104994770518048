<template>
  <el-form :model="form" ref="form" label-width="120px"  :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">术前四项</el-col>
      <el-col :span="21" class="right-col">
        <!--术前四项-->
        <el-row class="border_bottom" align="middle" type="flex">

              <el-col :span="24" class="">
                <el-col :span="12" class="">
                  <el-form-item class="inb" label="乙肝" prop="hbv">
                    <el-input v-model="form.hbv" placeholder="请输入内容" size="small"
                              style="width: 180px">
                      <template slot="append"> </template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="梅毒" prop="rpr">
                    <el-input v-model="form.rpr" placeholder="请输入内容" size="small"
                              style="width: 180px">
                      <template slot="append"></template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="艾滋" prop="hiv">
                    <el-input v-model="form.hiv" placeholder="请输入内容" size="small"
                              style="width: 180px">
                      <template slot="append"></template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="丙肝" prop="hcv">
                    <el-input v-model="form.hcv" placeholder="请输入内容" size="small"
                              style="width: 180px">
                      <template slot="append"></template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>

      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        hbv : '',//'乙肝',
        rpr: '', // '梅毒',
        hiv: '',//  '艾滋',
        hcv: '', // '丙肝',
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
