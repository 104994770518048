<template>
  <el-form :model="form" ref="form" label-width="120px" :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <!-- 肝功能十一项 -->
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold"> 肝功能五项</el-col>
      <el-col :span="21" class="right-col">
        <el-row class="" align="middle" type="flex">
          <el-col :span="24" class="" >

            <el-col :span="12">
              <el-form-item class="inb" label="谷丙转氨酶（GPT）" prop="gpt">
                <el-input v-model="form.gpt" placeholder="请输入内容" size="small">
                  <template slot="append">u/l</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item class="inb" label="谷草转氨酶（GOT）" prop="got">
                <el-input v-model="form.got" placeholder="请输入内容" size="small">
                  <template slot="append">u/l</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item class="inb" label="总胆红素（TBIL）" prop="tbil">
                <el-input v-model="form.tbil" placeholder="请输入内容" size="small">
                  <template slot="append">umol/L</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item class="inb" label="直接胆红素（DBIL）" prop="dbil">
                <el-input v-model="form.dbil" placeholder="请输入内容" size="small">
                  <template slot="append">umol/L</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item class="inb" label="间接胆红素（IBIL）" prop="ibil">
                <el-input v-model="form.ibil" placeholder="请输入内容" size="small">
                  <template slot="append">umol/L</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
    name: "",
    components: {},
    props: ['deptAuth'],
    data() {
      return {
        form: {
          // tpro: '', // 总蛋白
          // alp: '', //   碱性磷酸酶
          // ggt: '', //   谷氨酰转肽酶
          // alb: '', //   白蛋白
          // glb: '',//   球蛋白
          // leu: '', //   白球化
          gpt: '', //   谷丙转氨酶
          got: '', //   谷草转氨酶
          tbil: '', //  总胆红素
          dbil: '', //  直接胆红素
          ibil: '', //  间接胆红素
        },
      };
    },
    computed: {},
    watch: {},
    mounted() {

    },
    methods: {},
  };
</script>

<style lang="scss">
</style>
