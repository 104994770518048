<template>
  <el-form :model="form" ref="form" label-width="120px" :disabled="'0'!==deptAuth && '3' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">彩超</el-col>
      <el-col :span="21" class="right-col">

<!--        <el-row class="border_bottom" align="middle" type="flex">-->
<!--          <el-col :span="3" class="bold flex_center"> 胸部正位片 </el-col>-->
<!--          <el-col :span="21" class="border_left">-->
<!--            <el-row class="" align="middle" type="flex">-->
<!--              <el-col :span="0" class="bold flex_center">-->
<!--              </el-col>-->
<!--              <el-col :span="24" class="">-->
<!--                <el-form-item class="inb" label="状态" prop="fzjc_xbxxp_zt">-->
<!--                  <el-radio-group v-model="form.fzjc_xbxxp_zt">-->
<!--                    <el-radio label="正常"></el-radio>-->
<!--                    <el-radio label="异常"></el-radio>-->
<!--                  </el-radio-group>-->
<!--                </el-form-item>-->
<!--                <el-form-item v-if="form.fzjc_xbxxp_zt === '异常'" class="inb" label="" prop="fzjc_xbxxp_ztyc" label-width="0">-->
<!--                  <el-input v-model="form.fzjc_xbxxp_ztyc" placeholder="请输入内容" size="small">-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="检查情况描述" prop="fzjc_xbxxp_jcqkms">-->
<!--                  <el-input v-model="form.fzjc_xbxxp_jcqkms" placeholder="请输入内容" size="small">-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="诊断意见" prop="fzjc_xbxxp_zdyj">-->
<!--                  <el-input v-model="form.fzjc_xbxxp_zdyj" placeholder="请输入内容" size="small">-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-col>-->
<!--        </el-row>-->

        <!-- 腹部彩超 -->
        <el-row class="border_bottom" align="middle" type="flex">
          <el-col :span="3" class="bold flex_center"> 腹部彩超 </el-col>
          <el-col :span="21" class="border_left">
            <el-row align="middle" type="flex">
              <el-col :span="24" >
                <el-form-item class="inb" label="肝脏" prop="liver">
                  <el-input v-model="form.liver" placeholder="请输入内容" size="small">
                  </el-input>
                </el-form-item>
                <el-form-item class="inb" label="胆囊" prop="gallbladder">
                  <el-input v-model="form.gallbladder" placeholder="请输入内容" size="small">
                  </el-input>
                </el-form-item>
                <el-form-item class="inb" label="脾脏" prop="spleen">
                  <el-input v-model="form.spleen" placeholder="请输入内容" size="small">
                  </el-input>
                </el-form-item>
                <el-form-item class="inb" label="胰腺" prop="pancreas">
                  <el-input v-model="form.pancreas" placeholder="请输入内容" size="small">
                  </el-input>
                </el-form-item>
                <el-form-item class="inb" label="双肾" prop="bothsides">
                  <el-input v-model="form.bothsides" placeholder="请输入内容" size="small">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 盆腔彩超 -->
        <el-row class="border_bottom" align="middle" type="flex" v-show="packB || packC">
          <el-col :span="3" class="bold flex_center"> 盆腔彩超 </el-col>
          <el-col :span="21" class="border_left">
            <el-row class="" align="middle" type="flex">
              <el-col :span="0" class="bold flex_center">
              </el-col>
              <el-col :span="24" class="">
                <el-form-item class="inb" label="前列腺" prop="prostate">
                  <el-input v-model="form.prostate" placeholder="请输入内容" size="small">
                  </el-input>
                </el-form-item>
                <el-form-item class="inb" label="膀胱" prop="bladder">
                  <el-input v-model="form.bladder" placeholder="请输入内容" size="small">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          </el-row>

        <!-- TCD经颅多普勒-->
        <el-row class="" align="middle" type="flex" v-show="packC">
          <el-col :span="3" class="bold flex_center"> TCD经颅多普勒 </el-col>
          <el-col :span="21" class="border_left">

            <el-form-item label="TCD经颅多普勒" prop="tcd">
              <el-input v-model="form.tcd" placeholder="请输入内容" size="small">
              </el-input>
            </el-form-item>

          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-form>

</template>

<script>
export default {
  name: "",
  components: {},
  props: [
      'deptAuth', //部门权限
      'tjlx'      //体检套餐
  ],
  data() {
    return {
      form: {

        fzjc_xbxxp_zt: '', // 胸部正位片
        fzjc_xbxxp_ztyc: '', // 胸部正位片异常描述
        fzjc_xbxxp_jcqkms: '', // 胸部正位片检查情况
        fzjc_xbxxp_zdyj: '', // 胸部正位片诊断意见
        prostate: '', // 盆腔彩超-前列腺
        bladder: '', // 盆腔彩超-膀胱
        liver: '', // 腹部彩超-肝脏
        gallbladder: '', //腹部彩超-胆囊
        spleen: '', // 腹部彩超-脾脏
        pancreas: '', // 腹部彩超-胰腺
        bothsides: '', // 腹部彩超-双肾
        tcd: '', // 颅内多普勒血流图
      },
      pack0: true,
      packA: true,
      packB: true,
      packC: true,
    };
  },
  computed: {},
  watch: {
    "tjlx"(newPack){
      this.tjlx = newPack;

      this.pack0 = '胃镜体检套餐' === this.tjlx;
      // console.log("0 ",this.pack0)
      this.packA = '体检套餐A' === this.tjlx;
      // console.log("A ",this.packA)
      this.packB = '体检套餐B' === this.tjlx;
      // console.log("B",this.packB)
      this.packC = '体检套餐C' === this.tjlx;
      // console.log("C ",this.packC)
    }
  },
  mounted() {




  },
  // created() {
  //   this.$parent.$on('packChanged',this.onPackChanged);
  // },
  methods: {
    onPackChanged(pack0,A,B,C ){
      console.log("onPackChanged");
      this.pack0 = pack0;
      this.packA = A;
      this.packB = B;
      this.packC = C;
    }
  },
};
</script>

<style scoped>

</style>
