<template>
  <el-form :model="form" ref="form" label-width="120px" :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">肾功能三项</el-col>
      <el-col :span="21" class="right-col">
        <!-- 肾功能三项 -->
        <el-row class="border_bottom" align="middle" type="flex">

              <el-col :span="24" class="">

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="肌酐" prop="crea">
                    <el-input v-model="form.crea" placeholder="请输入内容" size="small">
                      <template slot="append">umol/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="尿酸" prop="ua">
                    <el-input v-model="form.ua" placeholder="请输入内容" size="small">
                      <template slot="append">umol/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="尿素" prop="bun">
                    <el-input v-model="form.bun" placeholder="请输入内容" size="small">
                      <template slot="append">umol/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>

      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        crea: '', //              肌酐
        ua: '', //                尿酸（UA）
        bun: '', //               尿素（BUN）
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
