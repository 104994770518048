<template>
  <el-form :model="form" ref="form"  :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">CT 检查</el-col>
      <el-col :span="21" class="right-col">
        <!-- CT 检查-->
          <el-form-item  label="检查所见" prop="painless_gastroscope" label-width="110px">
            <el-input v-model="form.ct_scan" placeholder="请输入内容" size="small">
            </el-input>
          </el-form-item>
          <el-form-item  label="检查结论" prop="indolent_enteroscope" label-width="110px">
            <el-input v-model="form.ct_conclusion" placeholder="请输入内容" size="small">
            </el-input>
          </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {

        ct_scan: '',  //检查所见
        ct_conclusion: '',  //检查结论
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
