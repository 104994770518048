<template>
  <el-form :model="form" ref="form" label-width="120px">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold"> 基础信息</el-col>
      <el-col :span="21" class="right-col">
        <el-row>
          <el-col :span="12">
            <el-form-item
                label="录入单位"
                prop="lrdw"
                :rules="$getRequireRule()"
            >
              <el-input v-model="form.lrdw" size="small" disabled></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="录入医生"
              prop="lrys"
              :rules="$getRequireRule()"
            >
              <el-input v-model="form.lrys" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
                label="自定义编号"
                prop="zdytjbh"
                :rules="$getRequireRule()"
            >
              <el-input v-model="form.zdytjbh" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="体检日期"
                prop="tjrq"
                :rules="$getRequireRule()"
            >
              <el-date-picker
                  v-model="form.tjrq"
                  type="datetime"
                  size="small"
                  placeholder="选择日期"
                  :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
                label="患者名称"
                prop="hzmc"
                :rules="$getRequireRule()"
            >
              <el-input v-model="form.hzmc" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="患者性别"
                prop="hzxb"
                :rules="$getRequireRule()"
            >
              <el-radio-group v-model="form.hzxb" size="small">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>

          <el-col :span="12">
            <el-form-item
                label="出生年月日"
                prop="hzsr"
                :rules="$getRequireRule()"
            >
              <el-date-picker
                  size="small"
                  v-model="form.hzsr"
                  type="date"
                  placeholder="选择日期"
                  @blur="onInputBirthDay">
              </el-date-picker>
              <!--              <el-input v-model="form.hzsfz" size="small" @blur="onBlurCard"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="患者年龄"
                prop="hznl"
                :rules="$getRequireRule()"
            >
              <el-input v-model="form.hznl" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="患者联系方式"
                prop="hzlxfs"
                :rules="$getRequireRule()"
            >
              <el-input v-model="form.hzlxfs" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>


        </el-row>

<!--        <el-row>-->
<!--          <el-col :span="12">-->
<!--            <el-form-item-->
<!--              label="自定义编号"-->
<!--              prop="zdytjbh"-->
<!--              :rules="$getRequireRule()"-->
<!--            >-->
<!--              <el-input v-model="form.zdytjbh" size="small"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <el-form-item label="备注" prop="bz">-->
<!--              <el-input size="small" v-model="form.bz"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
// import * from ''
import {autoNewNumber} from "@/http/report/detail";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      form: {
        tjrq: "",
        lrys: "",
        lrdw: "",
        jcxm: "",
        hzmc: "",
        hzsfz: "",
        hzsr: "",
        hzxb: "",
        hznl: "",
        hzlxfs: "",
        zdytjbh: "",
        // nr: "",
        // bz: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {
    async reset() {
      !this.form.lrys && (this.form.lrys = this.$store.state.userInfo.nickName);
      !this.form.lrdw && (this.form.lrdw = this.$store.state.userInfo.chain);
      !this.form.tjrq && (this.form.tjrq = new Date());
      await this.getNewNum();
    },
    // 自动生成自定义编号
    async getNewNum(){
      const res = await autoNewNumber();
      if (res.success) {
        this.form.zdytjbh = res.result;
      }
    },
    onBlurCard() {
      const idCard = this.form.hzsfz // 获取身份证号码输入框的值
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(idCard)) {
        this.getAgeByIdCard(idCard);
      } else {
        this.$message.error("身份证号码不正确");
      }
    },
    onInputBirthDay() {
      const hzsr = this.form.hzsr// 获取身份证号码输入框的值
      console.log(hzsr);
      let age = this.getAge();
      this.form.hznl= age // 设置表单中年龄字段的值
    },
    getAge(){
      let birthdays = new Date(this.form.hzsr);
      let d = new Date();
      let age =
          d.getFullYear() -
          birthdays.getFullYear() -
          (d.getMonth() < birthdays.getMonth() ||
          (d.getMonth() == birthdays.getMonth() &&
              d.getDate() < birthdays.getDate())
              ? 1
              : 0);
      return age;
    },


    // getAgeByIdCard(idCard) {
      // const len = idCard.length // 获取身份证号码长度

      // let sex = null
      // const myDate = new Date()
      // const month = myDate.getMonth() + 1
      // const day = myDate.getDate()
      // let age = 0

      // if (len === 18) {
      //   age = myDate.getFullYear() - idCard.substring(6, 10) - 1
      //   sex = idCard.substring(16, 17)
      //   if (idCard.substring(10, 12) < month || idCard.substring(10, 12) === month && idCard.substring(12, 14) <= day) age++
      // }
      // if (len === 15) {
      //   age = myDate.getFullYear() - idCard.substring(6, 8) - 1901
      //   sex = idCard.substring(13, 14)
      //   if (idCard.substring(8, 10) < month || idCard.substring(8, 10) === month && idCard.substring(10, 12) <= day) age++
      // }
    //   if (sex % 2 === 0) { sex = '女' } else { sex = '男' }
    //   this.form.hzxb = sex  // 设置表单中性别的值
    //
    // }
  }
};
</script>

<style lang="scss">
</style>
