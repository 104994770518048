<template>
  <el-form :model="form" ref="form" label-width="120px"  :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">凝血四项</el-col>
      <el-col :span="21" class="right-col">
        <!--凝血四项-->
        <el-row class="border_bottom" align="middle" type="flex">

              <el-col :span="24" class="">
                <el-col :span="12" class="">
                  <el-form-item class="inb" label="凝血酶原时间" prop="pt">
                    <el-input v-model="form.pt" placeholder="请输入内容" size="small">
                      <template slot="append">s</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="">
                  <el-form-item class="inb" label="活化部分凝血活酶时间" prop="aptt">
                    <el-input v-model="form.aptt" placeholder="请输入内容" size="small">
                      <template slot="append">s</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="">
                  <el-form-item class="inb" label="凝血酶时间" prop="tt">
                    <el-input v-model="form.tt" placeholder="请输入内容" size="small">
                      <template slot="append">s</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12" class="">
                  <el-form-item class="inb" label="纤维蛋白原" prop="fib">
                    <el-input v-model="form.fib" placeholder="请输入内容" size="small">
                      <template slot="append">g/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>

      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        pt: '', //            凝血酶原时间
        aptt: '', //          活化部分凝血活酶时间
        tt: '', //             凝血酶时间
        fib: '', //             纤维蛋白原
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
