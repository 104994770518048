import { render, staticRenderFns } from "./Tetrapolipidemia.vue?vue&type=template&id=08fa3ae4&scoped=true&"
import script from "./Tetrapolipidemia.vue?vue&type=script&lang=js&"
export * from "./Tetrapolipidemia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fa3ae4",
  null
  
)

export default component.exports