<template>
  <el-form :model="form" ref="form" label-width="120px"  :disabled="'0'!==deptAuth && '4' !==deptAuth">
  <!-- 血糖 -->
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold"> 血糖</el-col>

      <el-col :span="21" class="right-col">
        <el-row class="" align="middle" type="flex">
          <el-col :span="12">
            <el-form-item class="inb" label="空腹血糖" prop="fzjc_kfxtmmoll">
              <el-input v-model="form.fzjc_kfxtmmoll" placeholder="请输入内容" size="small">
                <template slot="append">u/l</template>
              </el-input>
            </el-form-item>
          </el-col>

<!--          <el-col :span="4" class="bold flex_center">-->
<!--            空腹血糖-->
<!--          </el-col>-->
<!--          <el-col :span="12" class="">-->
<!--            <el-form-item class="inb" label="" prop="fzjc_kfxtmmoll" label-width="0">-->
<!--              <el-input v-model="form.fzjc_kfxtmmoll" placeholder="请输入内容" size="small"-->
<!--                        style="width: 200px">-->
<!--                <template slot="append">mmol/L </template>-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--&lt;!&ndash;            <el-form-item class="inb" label="或" prop="fzjc_kfxtmgdl" label-width="20px">&ndash;&gt;-->
<!--&lt;!&ndash;              <el-input v-model="form.fzjc_kfxtmgdl" placeholder="请输入内容" size="small"&ndash;&gt;-->
<!--&lt;!&ndash;                        style="width: 200px">&ndash;&gt;-->
<!--&lt;!&ndash;                <template slot="append">mg/dL </template>&ndash;&gt;-->
<!--&lt;!&ndash;              </el-input>&ndash;&gt;-->
<!--&lt;!&ndash;            </el-form-item>&ndash;&gt;-->
<!--          </el-col>-->
        </el-row>
<!--        <el-row class="" align="middle" type="flex">-->
<!--          <el-col :span="2" class="bold flex_center">-->
<!--            餐后血糖-->
<!--          </el-col>-->
<!--          <el-col :span="21" class="">-->
<!--            <el-form-item class="inb" label="" prop="fzjc_chxt" label-width="0">-->
<!--              <el-input v-model="form.fzjc_chxt" placeholder="请输入内容" size="small"-->
<!--                        style="width: 200px">-->
<!--                <template slot="append">mmol/L </template>-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
      </el-col>
    </el-row>
  </el-form>
</template>

<script>

export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        fzjc_kfxtmmoll: '', // 辅助检查-空腹血糖mmol/L
        // fzjc_kfxtmgdl: '', // 辅助检查-空腹血糖mg/dL
        // fzjc_chxt: '', // 辅助检查-餐后血糖

      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style lang="scss">
</style>
