<template>
  <el-form :model="form" ref="form"  :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">内窥镜检查</el-col>
      <el-col :span="21" class="right-col">
        <!--内窥镜检查-->
          <el-form-item  label="无痛胃镜" prop="painless_gastroscope" label-width="110px">
            <el-input v-model="form.painless_gastroscope" placeholder="请输入内容" size="small">
            </el-input>
          </el-form-item>
          <el-form-item  label="无痛肠镜" prop="indolent_enteroscope" label-width="110px">
            <el-input v-model="form.indolent_enteroscope" placeholder="请输入内容" size="small">
            </el-input>
          </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        painless_gastroscope: '', //            无痛胃镜
        indolent_enteroscope: '', //             无痛肠镜
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
