<template>
  <el-form :model="form" ref="form" label-width="60px">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">健康评价</el-col>
      <el-col :span="21" class="right-col">
        <el-row>
          <el-col :span="20">
            <el-form-item label-width="0" label="" prop="jkpj_sfyc">
              <el-radio-group v-model="form.jkpj_sfyc">
                <el-radio label="体检无异常">体检无异常</el-radio>
                <el-radio label="有异常">有异常</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="text-align: right">
            <el-button
              class="mr16 ml16 mt8"
              type="primary"
              round
              icon="el-icon-s-opportunity"
              @click="calculate"
              >智能计算</el-button
            >
          </el-col>
        </el-row>
        <el-form-item label="一般状况" prop="jkpj_yc1" label-width="110px">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc1"
          ></el-input>
        </el-form-item>
        <el-form-item label="口腔检查" prop="jkpj_yc2" label-width="110px">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc2"
          ></el-input>
        </el-form-item>
        <el-form-item label="心电图" prop="jkpj_yc3" label-width="110px">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc3"
          ></el-input>
        </el-form-item>
        <el-form-item label="彩超" prop="jkpj_yc4" label-width="110px">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc4"
          ></el-input>
        </el-form-item>
        <el-form-item label="乙肝五项" prop="jkpj_yc5" label-width="110px" v-show="packA || packB || packC">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc5"
          ></el-input>
        </el-form-item>
        <el-form-item label="血糖" prop="jkpj_yc6" label-width="110px" v-show="packA || packB || packC">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc6"
          ></el-input>
        </el-form-item>
        <el-form-item label="肝功能五项" prop="jkpj_yc7" label-width="110px" v-show="packA || packB || packC">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc7"
          ></el-input>
        </el-form-item>
        <el-form-item label="血脂四项" prop="jkpj_yc8" label-width="110px" v-show="packA || packB || packC">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc8"
          ></el-input>
        </el-form-item>
        <el-form-item label="肾功能三项" prop="jkpj_yc9" label-width="110px" v-show="packA || packB || packC">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc9"
          ></el-input>
        </el-form-item>
        <el-form-item label="血常规" prop="jkpj_yc10" label-width="110px">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc10"
          ></el-input>
        </el-form-item>
        <el-form-item label="尿常规" prop="jkpj_yc11" label-width="110px" v-show="packB || packC">
          <el-input
            type="text"
            :disabled="form.jkpj_sfyc == '体检无异常'"
            v-model="form.jkpj_yc11"
          ></el-input>
        </el-form-item>
<!--        <el-form-item label="其他" prop="jkpj_yc12" label-width="110px">-->
<!--          <el-input-->
<!--            type="text"-->
<!--            :disabled="form.jkpj_sfyc == '体检无异常'"-->
<!--            v-model="form.jkpj_yc12"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="凝血四项" prop="jkpj_yc13" label-width="110px" v-show="pack0">
          <el-input
              type="text"
              :disabled="form.jkpj_sfyc == '体检无异常'"
              v-model="form.jkpj_yc13"
          ></el-input>
        </el-form-item>
        <el-form-item label="术前四项" prop="jkpj_yc14" label-width="110px" v-show="pack0">
          <el-input
              type="text"
              :disabled="form.jkpj_sfyc == '体检无异常'"
              v-model="form.jkpj_yc14"
          ></el-input>
        </el-form-item>
        <el-form-item label="内窥镜检查" prop="jkpj_yc15" label-width="110px" v-show="pack0">
          <el-input
              type="text"
              :disabled="form.jkpj_sfyc == '体检无异常'"
              v-model="form.jkpj_yc15"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { getAbnormalInfo } from "@/http/report/detail";
import { _toCamel } from "@/utils/index";
import { Array_Prefix } from "@/constant";

export default {
  name: "",
  components: {},
  props: {
    getAllFormData: {
      type: Function,
      default: () => {
        return {};
      },
    },
    tjlx: {}
  },
  data() {
    return {
      pack0: true,
      packA: true,
      packB: true,
      packC: true,
      form: {
        jkpj_sfyc: "",
        jkpj_yc1: "",
        jkpj_yc2: "",
        jkpj_yc3: "",
        jkpj_yc4: "",
        jkpj_yc5: "",
        jkpj_yc6: "",
        jkpj_yc7: "",
        jkpj_yc8: "",
        jkpj_yc9: "",
        jkpj_yc10: "",
        jkpj_yc11: "",
        jkpj_yc12: "",
        jkpj_yc13: "",
        jkpj_yc14: "",
        jkpj_yc15: "",
      },
      other: "",
      zhengzhuang: [],
    };
  },
  computed: {},
  watch: {
    "tjlx"(newPack){
      this.tjlx = newPack;

      this.pack0 = '胃镜体检套餐' === this.tjlx;
      this.packA = '体检套餐A' === this.tjlx;
      this.packB = '体检套餐B' === this.tjlx;
      this.packC = '体检套餐C' === this.tjlx;

    }
  },
  mounted() {},
  methods: {
    calculate() {
      this.$confirm("此操作将覆盖已填写的异常内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.submitCalculate();
      });
    },
    async submitCalculate() {
      this.loading = true;
      const res = await getAbnormalInfo(this.getAllFormData());
      this.loading = false;
      if (res.success) {
        console.log(res, Array_Prefix);
        const result = res.result;
        for (const _key in this.form) {
          const key = _toCamel(_key);
          const value = result[key];
          this.form[_key] = value ? value.replace(Array_Prefix, "") : value;
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
