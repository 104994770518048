<template>
  <el-form :model="form" ref="form" label-width="" :disabled="'0'!==deptAuth && '3' !==deptAuth">
  <!-- 心电图 -->
    <el-row class="row-index">
    <el-col :span="2" class="bold flex_center"> 心电图 </el-col>
    <el-col :span="21" class="">
      <el-row class="" align="middle" type="flex">
        <el-col :span="24" class="border_left">
          <el-row class="border_bottom" align="middle" type="flex">
            <el-col :span="3" class="flex_center bold" type="flex">状态</el-col>
            <el-col :span="21" class="border_left">
              <el-form-item  prop="" class="inb w100"  label-width="0px" >
                <el-radio-group v-model="form.fzjc_xdt_zt">
                  <el-radio label="正常"></el-radio>
                  <el-radio label="临界ECG"></el-radio>
                  <el-radio label="异常"></el-radio>
                </el-radio-group>
                <el-form-item class="inb w400px" label="异常描述" prop="fzjc_xdt_ycms" label-width="68px">
                  <el-input v-model="form.fzjc_xdt_ycms" placeholder="请输入内容" size="small"></el-input>
                </el-form-item>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row class="border_bottom" align="middle" type="flex">
            <el-col :span="3" class="flex_center bold" type="flex">描述</el-col>
            <el-col :span="21" class="border_left flex">
              <el-form-item  prop="" class="inb w100"  label-width="0px" >
                <el-input class="inb" v-model="form.fzjc_xdt_ms" placeholder="请输入内容" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row class="" align="middle" type="flex">
            <el-col :span="3" class="flex_center bold" type="flex">提示</el-col>
            <el-col :span="21" class="border_left flex">
              <el-form-item  prop="" class="inb w100"  label-width="0px" >
                <el-input v-model="form.fzjc_xdt_ts" placeholder="请输入内容" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

<!--          <el-form-item label="心电图报告医生" prop="fzjc_xdt_xdtbgys">-->
<!--            <el-input v-model="form.fzjc_xdt_xdtbgys" placeholder="请输入内容" size="small">-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="其他心电图报告医生" prop="fzjc_xdt_qtxdtbgys">-->
<!--            <el-input v-model="form.fzjc_xdt_qtxdtbgys" placeholder="请输入内容" size="small">-->
<!--            </el-input>-->
<!--          </el-form-item>-->
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
          fzjc_xdt_zt: '', // 辅助检查-心电图-状态
          // fzjc_xdt_ycms: '', // 辅助检查-心电图-异常描述
          // fzjc_xdt_xdtxl: '', // 辅助检查-心电图-心电图心律
          // fzjc_xdt_xfl: '', // 辅助检查-心电图-心房率
          // fzjc_xdt_bpmxsl: '', // 辅助检查-心电图-bpm心室率
          // fzjc_xdt_dz: '', // 辅助检查-心电图-电轴
          // fzjc_xdt_prjq: '', // 辅助检查-心电图-PR间期
          // fzjc_xdt_qrssx: '', // 辅助检查-心电图-QRS时限
          // fzjc_xdt_qtjq: '', // 辅助检查-心电图-QT间期
          fzjc_xdt_ms: '', // 辅助检查-心电图-描述
          fzjc_xdt_ts: '', // 辅助检查-心电图-提示
          // fzjc_xdt_xdtbgys: '', // 辅助检查-心电图-心电图报告医生
          // fzjc_xdt_qtxdtbgys: '', // 辅助检查-心电图-其他心电图报告医生
      },

    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
