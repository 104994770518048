<template>
  <el-form :model="form" ref="form" label-width="120px"  :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">血脂四项</el-col>
      <el-col :span="21" class="right-col">
        <!-- 血脂 -->
        <el-row class="" align="middle" type="flex">

              <el-col :span="24" class="border_left">
                <el-col :span="12" class="">
                  <el-form-item class="inb" label="甘油三脂" prop="tg">
                    <el-input v-model="form.tg" placeholder="请输入内容" size="small">
                      <template slot="append">mmol/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="总胆固醇" prop="chol">
                    <el-input v-model="form.chol" placeholder="请输入内容" size="small">
                      <template slot="append">mmol/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="高密度脂蛋白" prop="hdl">
                    <el-input v-model="form.hdl" placeholder="请输入内容" size="small">
                      <template slot="append">mmol/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12" class="">
                  <el-form-item class="inb" label="低密度脂蛋白" prop="ldl">
                    <el-input v-model="form.ldl" placeholder="请输入内容" size="small">
                      <template slot="append">mmol/L</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-row>

      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        tg: '', //甘油三脂
        chol: '', //总胆固醇
        hdl: '', //高密度脂蛋白
        ldl: '', //低密度脂蛋白
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
