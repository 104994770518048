<template>
  <el-form :model="form" ref="form" label-width="120px" :disabled="'0'!==deptAuth && '4' !==deptAuth">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">尿常规</el-col>
      <el-col :span="21" class="right-col">
        <!-- 尿常规 -->
        <el-row class="border_bottom" align="middle" type="flex">

          <el-col :span="24" class="">

            <el-col :span="12" class="">
              <el-form-item class="inb" label="维生素C" prop="vitaminC">
                <el-input v-model="form.vitaminC" placeholder="请输入内容" size="small">
                  <template slot="append">mmol/L</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿潜血" prop="bld">
                <el-input v-model="form.bld" placeholder="请输入内容" size="small">
                  <template slot="append">cells/ul</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿胆红素" prop="bil">
                <el-input v-model="form.bil" placeholder="请输入内容" size="small">
                  <template slot="append">μmol/L</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿酮体" prop="ket">
                <el-input v-model="form.ket" placeholder="请输入内容" size="small">
                  <template slot="append">mmol/L</template>
                </el-input>

              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿比重" prop="sg">
                <el-input v-model="form.sg" placeholder="请输入内容" size="small">
                  <template slot="append">g/mL</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿酸碱度" prop="ph">
                <el-input v-model="form.ph" placeholder="请输入内容" size="small">
                  <template slot="append">ph</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿亚硝酸盐" prop="nit">
                <el-input v-model="form.nit" placeholder="请输入内容" size="small">
                  <template slot="append">/ml</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿蛋白质" prop="pro">
                <el-input v-model="form.pro" placeholder="请输入内容" size="small">
                  <template slot="append">g/l</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" >
              <el-form-item class="inb" label="尿葡萄糖" prop="glu">
                <el-input v-model="form.glu" placeholder="请输入内容" size="small">
                  <template slot="append">mmol/l</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿胆原" prop="uro">
                <el-input v-model="form.uro" placeholder="请输入内容" size="small">
                  <template slot="append">umol/l</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" class="">
              <el-form-item class="inb" label="尿白细胞" prop="u_leu">
                <el-input v-model="form.uLeu" placeholder="请输入内容" size="small">
                  <template slot="append">cells/ul</template>
                </el-input>
              </el-form-item>
            </el-col>

          </el-col>

        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      form: {
        vitaminC: '', //            维生素C
        bld: '', //                 尿潜血
        bil: '', //                 尿胆红素
        ket: '', //                 尿酮体
        sg: '', //                  尿比重
        ph: '', //                  尿酸碱度
        nit: '', //                 尿亚硝酸盐
        pro: '', //                 尿蛋白质
        glu: '', //                 尿葡萄糖
        uro: '', //                 尿胆原
        uLeu: '', //                 尿白细胞
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {

  },
  methods: {},
};
</script>

<style scoped>

</style>
