<template>
  <el-form :model="form" ref="form" label-width="120px" :disabled="'0'!==deptAuth && '1' !==deptAuth ">
    <el-row class="row-index">
      <el-col :span="2" class="left-col bold">一般状况</el-col>
      <el-col :span="21" class="border_left">
        <el-row class="">
          <el-col :span="12">
            <el-form-item label="体 温" prop="ybzt_tw">
              <el-input
                v-model="form.ybzt_tw"
                placeholder="请输入内容"
                size="small">
                <template slot="append">℃ </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="脉 率" prop="ybzt_ml">
              <el-input
                v-model="form.ybzt_ml"
                placeholder="请输入内容"
                size="small">
                <template slot="append">次/每分钟</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="">
          <el-col :span="24">
            <el-row>
              <el-col :span="12">
                <el-form-item label="血压-收缩压" prop="ybzt_xyzcssy">
                  <el-input
                    v-model="form.ybzt_xyzcssy"
                    placeholder="请输入内容"
                    size="small"
                  >
                    <template slot="append">mmhg </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="血压-舒张压" prop="ybzt_xyzcszy">
                  <el-input
                    v-model="form.ybzt_xyzcszy"
                    placeholder="请输入内容"
                    size="small"
                  >
                    <template slot="append">mmhg </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row class="">
          <el-col :span="12">
            <el-form-item label="体 重" prop="ybzt_tz">
              <el-input
                  v-model="form.ybzt_tz"
                  placeholder="请输入内容"
                  size="small"
              >
                <template slot="append">kg </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身 高" prop="ybzt_sg">
              <el-input
                v-model="form.ybzt_sg"
                placeholder="请输入内容"
                size="small"
                @blur="onBlurSg"
              >
                <template slot="append">cm </template>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row class="">
          <el-col :span="12">
            <el-form-item label="体质指数(BMI)" prop="ybzt_tzzs_bmi">
              <el-input
                  v-model="form.ybzt_tzzs_bmi"
                  placeholder="请输入内容"
                  size="small"
              >
                <template slot="append">kg/m2 </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="呼吸频率"
                prop="ybzt_hxpl"
            >
              <el-input
                  v-model="form.ybzt_hxpl"
                  placeholder="请输入内容"
                  size="small"
              >
                <template slot="append">次/每分钟</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { yibanzhuangkuang } from "@/utils/dict";
// import axios from "axios";

export default {
  name: "",
  components: {},
  props: ['deptAuth'],
  data() {
    return {
      selectedDoctor:'',
      options:[],
      form: {
        doctor_name: "", // 检查医生
        ybzt_tw: "37", // 一般状态-体温
        ybzt_ml: "", // 一般状态-脉率
        ybzt_hxpl: "", // 一般状态-呼吸频率
        ybzt_xyzcssy: "", // 一般状态-血压-收缩压
        ybzt_xyzcszy: "", // 一般状态-血压-舒张压
        // ybzt_xyycssy: "", // 一般状态-血压右侧
        // ybzt_xyycszy: "", // 一般状态-血压右侧
        ybzt_sg: "", // 一般状态-身高
        ybzt_tz: "", // 一般状态-体重
        ybzt_yw: "", // 一般状态-腰围
        ybzt_tzzs_bmi: "", // 一般状态-体质指数(BMI)
        ybzt_tunwei: "", // 一般状态-臀围
        ybzt_lnrjkztzwpg: "", // 一般状态-老年人健康状态自我评估
        ybzt_lnrshzlnlzwpg: "", // 一般状态-老年人生活自理能力自我评估
        ybzt_lnrrzgn: "", // 一般状态-老年人认知功能
        ybzt_jyzlztjc_zf: "", // 一般状态-简易智力状态检查-总分
        ybzt_lnrqgzt: "", // 一般状态-老年人情感状态
        ybzt_lnryypfjc_zf: "", // 一般状态-老年人抑郁评分检查-总分
      },
      jkzt: yibanzhuangkuang.jkzt, // 健康状态
      shzlnl: yibanzhuangkuang.shzlnl, // 生活自理能力
      rzgn: yibanzhuangkuang.rzgn, // 老年人认知功能
      qgzt: yibanzhuangkuang.qgzt, // 老年人情感状态
    };
  },
  // async created() {
  //   try {
  //     const response = await axios.get('/health/select/doctor'); // 替换为你的API端点
  //     this.options = response.data;
  //   } catch (error) {
  //     console.error('Error fetching options:', error);
  //   }
  // },
  computed: {},
  watch: {},
  mounted() {
    this.form = {
      doctor_name: "", // 检查医生
      ybzt_tw: "", // 一般状态-体温
      ybzt_ml: "", // 一般状态-脉率
      ybzt_hxpl: "", // 一般状态-呼吸频率
      ybzt_xyzcssy: "", // 一般状态-血压左侧
      ybzt_xyycssy: "", // 一般状态-血压右侧
      ybzt_xyzcszy: "", // 一般状态-血压左侧
      ybzt_xyycszy: "", // 一般状态-血压右侧
      ybzt_sg: "", // 一般状态-身高
      ybzt_tz: "", // 一般状态-体重
      ybzt_yw: "", // 一般状态-腰围
      ybzt_tzzs_bmi: "", // 一般状态-体质指数(BMI)
      ybzt_tunwei: "", // 一般状态-臀围
      ybzt_lnrjkztzwpg: "满意", // 一般状态-老年人健康状态自我评估
      ybzt_lnrshzlnlzwpg: "可自理", // 一般状态-老年人生活自理能力自我评估
      ybzt_lnrrzgn: "初筛阴性", // 一般状态-老年人认知功能
      ybzt_jyzlztjc_zf: "", // 一般状态-简易智力状态检查-总分
      ybzt_lnrqgzt: "初筛阴性", // 一般状态-老年人情感状态
      ybzt_lnryypfjc_zf: "", // 一般状态-老年人抑郁评分检查-总分
    }
  },
  methods: {
    // BMI = 体重(kg) / 身高(m)^2
    onBlurSg(){
      const ybztTz = this.form.ybzt_tz;
      const ybztSg = this.form.ybzt_sg / 100;
      let BMI = ybztTz / ( ybztSg * ybztSg) ;

      this.form.ybzt_tzzs_bmi = BMI.toFixed(2);
    }
  },
};
</script>

<style lang="scss">

</style>
